import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as StaffStore from '../../../store/reducers/staff'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Dimmer,
  Loader,
  Table,
  Container,
  Icon
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { report: any, connected: boolean, options: any, settings: any } &
    typeof ReportsStore.actionCreators &
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>

class RiasecHTML extends React.PureComponent<ReportProps> {
    public componentDidMount() {
        this.props.fetchFacilities()
        this.props.fetchSettings()
        this.props.socketConnect()
    }

    public componentDidUpdate(prevProps:any) {
      if (!prevProps.connected && this.props.connected && !this.props.report) {
          this.props.generateRIASEC()
      }
    }

    private codeColor(num: string): string {
        switch (num) {
            case "1":
                return '#2185d0';
            case "2":
                return '#2185d0';
            case "3":
                return '#21ba45';
            case "4":
                return '#21ba45';
            case "5":
                return '#21ba45';
            case "6":
                return '#db2828';
            case "7":
                return '#db2828';
            default:
                return '#767676';
        }
      }

    private codeDisplay(code: string): string {
      switch (code) {
          case "R":
              return 'Realistic';
          case "I":
              return 'Investigative';
          case "A":
              return 'Artistic';
          case "S":
              return 'Social';
          case "E":
              return 'Enterprising';
          case "C":
              return 'Conventional';
          default:
              return code;
      }
    }

    public render() {
      if (!this.props.report) {
        return (
          <Fragment>
            <Dimmer active inverted>
              <Loader content='Generating report...' />
            </Dimmer>
          </Fragment>
        )
      } else if (this.props.report.loading) {
        if (this.props.connected) {
          return (
            <Fragment>
              <Dimmer active inverted>
                <Loader content='Generating report...' />
              </Dimmer>
            </Fragment>
          )
        } else {
          return (
            <Fragment>
              <Dimmer active inverted>
                <Icon name='warning sign' color='red' size='huge' />
                <div className='name' style={{textAlign:'center',color:'black',marginTop:10}}><b>Error</b></div>
              </Dimmer>
            </Fragment>
          )
        }
      } else {
        let params = qs.parse(window.location.search.substr(1, window.location.search.length));
        let data = this.props.report.generated
        let options = this.props.options
        let facilities = typeof params.facilities == 'string' ? [params.facilities] : params.facilities
        let num = 0

        switch (params.reportType) {
        case 'standard':
          return (
            <Container fluid>
              <div style={{textAlign:'center',marginBottom:15}}>
                <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>RIASEC Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
              </div>
              <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == "standard" ? 'Standard' : 'Detailed'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Sort by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.sortBy == "name" ? 'Inmate name' : 'RIASEC code'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Include code counts:</div><div style={{display:'inline-block',fontWeight:400}}>{params.combinations == "true" ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
              </div>
              <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                <Table.Body>
                  {data.facilities.length == 0 ? <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                    <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                  </Table.Row> : null}
                  {data.facilities.map((facility:any, f:number) => (
                    <Fragment key={facility.abbreviation}>
                      <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:160}}><div><span>SID</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:160}}><div><span>Code</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:20}}></span></div></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row positive>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.abbreviation}</b></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                      </Table.Row>
                      {facility.inmates.map((inmate:any, i:number) => {
                        num += 1
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={inmate.recordID}>
                            <Table.Cell warning={inmate.flat}><div style={{whiteSpace:'nowrap'}}>{inmate.name}</div></Table.Cell>
                            <Table.Cell warning={inmate.flat}>{inmate.sid}</Table.Cell>
                            <Table.Cell warning={inmate.flat}>{_.map(inmate.code, (char:string, c:number) => <span style={{fontWeight:'bold',color:this.codeColor(inmate.weights[c])}}>{char}</span>)}</Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                        )
                      })}
                      {params.combinations == "true" ? <Fragment>
                        {_.any(facility.codes, (c:any) => c.code.length == 1) ? <Fragment>
                          <Table.Row positive>
                            <Table.Cell />
                            <Table.Cell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>Totals</b></Table.Cell>
                            <Table.Cell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.Cell>
                          </Table.Row>
                          {_.chunk(_.filter(facility.codes, (c:any) => c.code.length == 1), 2).map((codes:any[], c:number) => {
                            num += 1
                            return (
                              <Table.Row style={{backgroundColor:(num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)')}} key={c}>
                                <Table.Cell />
                                <Table.Cell><span style={{fontWeight:'bold'}}>{this.codeDisplay(codes[0].code)}:</span> {codes[0].inmates.length}</Table.Cell>
                                <Table.Cell>{codes.length > 1 ? <Fragment><span style={{fontWeight:'bold'}}>{this.codeDisplay(codes[1].code)}:</span> {codes[1].inmates.length}</Fragment> : null}</Table.Cell>
                                <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                              </Table.Row>
                            )
                          })}
                        </Fragment> : null}
                        {_.any(facility.codes, (c:any) => params.two == "true" ? c.code.length > 1 : c.code.length > 2) ? <Fragment>
                          <Table.Row>
                            <Table.Cell />
                            <Table.Cell positive colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>Combinations</b></Table.Cell>
                            <Table.Cell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.Cell>
                          </Table.Row>
                          {_.chunk(_.filter(facility.codes, (c:any) => params.two == "true" ? c.code.length > 1 : c.code.length > 2), 2).map((codes:any[], c:number) => {
                            num += 1
                            return (
                              <Table.Row style={{backgroundColor:(num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)')}} key={c}>
                                <Table.Cell />
                                <Table.Cell><span style={{fontWeight:'bold'}}>{this.codeDisplay(codes[0].code)}:</span> {codes[0].inmates.length}</Table.Cell>
                                <Table.Cell>{codes.length > 1 ? <Fragment><span style={{fontWeight:'bold'}}>{this.codeDisplay(codes[1].code)}:</span> {codes[1].inmates.length}</Fragment> : null}</Table.Cell>
                                <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                              </Table.Row>
                            )
                          })}
                        </Fragment> : null}
                      </Fragment> : null}
                    </Fragment>
                  ))}
                </Table.Body>
              </Table>
              <div className='footer'>
                <div className='left'>
                    {new Date().today() + " @ " + new Date().timeNow()}
                </div>
                <div className='right'>
                    {data.staffID} / {data.printID}
                </div>
                <div className='center'>{this.props.report.copyright}</div>
              </div>
            </Container>
          )
        case 'detailed':
          return (
            <Container fluid>
              <div style={{textAlign:'center',marginBottom:15}}>
                <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>RIASEC Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
              </div>
              <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == "standard" ? 'Standard' : 'Detailed'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Sort by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.sortBy == "name" ? 'Inmate name' : 'RIASEC code'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Include code counts:</div><div style={{display:'inline-block',fontWeight:400}}>{params.combinations == "true" ? 'Yes' : 'No'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
              </div>
              <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                <Table.Body>
                  {data.facilities.length == 0 ? <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                    <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                  </Table.Row> : null}
                  {data.facilities.map((facility:any, f:number) => (
                    <Fragment key={facility.abbreviation}>
                      <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:160}}></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:160}}><div><span>SID</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:20}}></span></div></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row positive>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.abbreviation}</b></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                      </Table.Row>
                      {_.filter(facility.codes, (c:any) => params.two == "true" ? c.code.length > 1 : c.code.length > 2).map((code:any, c:number) => {
                        return (
                          <Fragment>
                            <Table.Row warning>
                              <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{code.code}</b></Table.HeaderCell>
                              <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                            </Table.Row>
                            {code.inmates.map((inmate:any, i:number) => {
                              num += 1
                              return (
                                <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={inmate.recordID}>
                                  <Table.Cell warning={inmate.flat} colSpan={2}><div style={{whiteSpace:'nowrap'}}>{inmate.name}</div></Table.Cell>
                                  <Table.Cell warning={inmate.flat}>{inmate.sid}</Table.Cell>
                                  <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                </Table.Row>
                              )
                            })}
                          </Fragment>
                        )
                      })}
                      {params.combinations == "true" ? <Fragment>
                        {_.any(facility.codes, (c:any) => c.code.length == 1) ? <Fragment>
                          <Table.Row positive>
                            <Table.Cell />
                            <Table.Cell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>Totals</b></Table.Cell>
                            <Table.Cell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.Cell>
                          </Table.Row>
                          {_.chunk(_.filter(facility.codes, (c:any) => c.code.length == 1), 2).map((codes:any[], c:number) => {
                            num += 1
                            return (
                              <Table.Row style={{backgroundColor:(num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)')}} key={c}>
                                <Table.Cell />
                                <Table.Cell><span style={{fontWeight:'bold'}}>{this.codeDisplay(codes[0].code)}:</span> {codes[0].inmates.length}</Table.Cell>
                                <Table.Cell>{codes.length > 1 ? <Fragment><span style={{fontWeight:'bold'}}>{this.codeDisplay(codes[1].code)}:</span> {codes[1].inmates.length}</Fragment> : null}</Table.Cell>
                                <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                              </Table.Row>
                            )
                          })}
                        </Fragment> : null}
                        {_.any(facility.codes, (c:any) => params.two == "true" ? c.code.length > 1 : c.code.length > 2) ? <Fragment>
                          <Table.Row>
                            <Table.Cell />
                            <Table.Cell positive colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>Combinations</b></Table.Cell>
                            <Table.Cell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.Cell>
                          </Table.Row>
                          {_.chunk(_.filter(facility.codes, (c:any) => params.two == "true" ? c.code.length > 1 : c.code.length > 2), 2).map((codes:any[], c:number) => {
                            num += 1
                            return (
                              <Table.Row style={{backgroundColor:(num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)')}} key={c}>
                                <Table.Cell />
                                <Table.Cell><span style={{fontWeight:'bold'}}>{this.codeDisplay(codes[0].code)}:</span> {codes[0].inmates.length}</Table.Cell>
                                <Table.Cell>{codes.length > 1 ? <Fragment><span style={{fontWeight:'bold'}}>{this.codeDisplay(codes[1].code)}:</span> {codes[1].inmates.length}</Fragment> : null}</Table.Cell>
                                <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                              </Table.Row>
                            )
                          })}
                        </Fragment> : null}
                      </Fragment> : null}
                    </Fragment>
                  ))}
                </Table.Body>
              </Table>
              <div className='footer'>
                <div className='left'>
                    {new Date().today() + " @ " + new Date().timeNow()}
                </div>
                <div className='right'>
                    {data.staffID} / {data.printID}
                </div>
                <div className='center'>{this.props.report.copyright}</div>
              </div>
            </Container>
          )
        }
      }
    }
}

export default connect(
  (state: ApplicationState) => { return { report: state.reports.reports.riasec, connected: state.reports.connected, options: state.reports.options, settings: state.staff.settings } },
  { ...StaffStore.actionCreators, ...ReportsStore.actionCreators }
)(RiasecHTML as any)
