import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import ClassSyncSchedules from './ClassSyncSchedules'
import ClassSyncRosters from './ClassSyncRosters'
import ClassSyncCapacities from './ClassSyncCapacities'
import { format, getDay, addWeeks, subWeeks } from 'date-fns'
import DatePicker from 'react-datepicker'
import Cleave from "cleave.js/react"
import {
  Container,
  Form,
  Grid,
  Input,
  Select,
  Radio,
  Checkbox,
  Popup,
  Button,
  Header,
  Divider,
  Modal,
  Table,
  Icon,
  Tab,
  Label
} from 'semantic-ui-react'
const _ = require('underscore')

type RosterProps =
    { class: PanesStore.Class, num: 1 | 2, admin: boolean, print: boolean, hide: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassData extends React.PureComponent<RosterProps> {
    public componentDidMount() {
        this.props.refreshClass(this.props.num, this.props.class.info.recordID)
        /*this.props.fetchClassWaitlist(this.props.num, this.props.class.info.recordID)
        this.props.fetchClassApprovals(this.props.num, this.props.class.info.recordID)*/
    }

    public dates() {
      var dates = []
      var date = new Date(this.props.class.data.dateBegins)
      var end = addWeeks(new Date(this.props.class.data.dateBegins), 52)
      const today = new Date(new Date().toDateString())

      while (subWeeks(date, 1) >= today) {
        date = subWeeks(date, 1)
      }

      while (date <= end) {
        dates.push(date)
        date = addWeeks(date, 1)
      }

      return dates
    }

    public render() {
        const days = [{ key: 0, value: 0, text: 'Sunday' }, { key: 1, value: 1, text: 'Monday' }, { key: 2, value: 2, text: 'Tuesday' }, { key: 3, value: 3, text: 'Wednesday' }, { key: 4, value: 4, text: 'Thursday' }, { key: 5, value: 5, text: 'Friday' }, { key: 6, value: 6, text: 'Saturday' }]
        const info = this.props.class.info
        const options = this.props.class.options
        const schedules = this.props.class.schedules
        const newSchedule = this.props.class.schedule
        const hiatusList = this.props.class.hiatus
        const newHiatus = this.props.class.newHiatus

        var classPanes = [
          { menuItem: 'Schedules', render: () => <Tab.Pane><ClassSyncSchedules class={this.props.class} num={this.props.num} /></Tab.Pane> },
          { menuItem: 'Rosters', render: () => <Tab.Pane><ClassSyncRosters class={this.props.class} num={this.props.num} /></Tab.Pane> }
        ]

        var schedulePanes = [
          { menuItem: 'Rosters', render: () => <Tab.Pane><ClassSyncRosters class={this.props.class} num={this.props.num} /></Tab.Pane> }
        ]

        return (
          <Container fluid>
            <Header as='h3' style={{marginTop:20}}>Info</Header>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Select}
                  label='Facility'
                  name='facilityID'
                  options={options.facilities}
                  value={info.facilityID}
                  onChange={(e:any, data:any) => this.props.admin ? this.props.infoChange(this.props.num, 'facilityID', data.value as number) : null}
                  className='transparent'
                  width={8}
                />
                <Form.Field
                  control={Select}
                  label='Default Class Location'
                  name='defaultLocationID'
                  options={[{key:0,value:null,text:''}].concat(options.locations)}
                  value={info.defaultLocationID}
                  onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'defaultLocationID', data.value as number)}
                  width={8}
                  className={this.props.print ? 'transparent' : ''}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Select}
                  label='Internal Name'
                  name='titleID'
                  value={info.titleID}
                  options={options.titles}
                  onChange={(e:any, data:any) => this.props.admin ? this.props.infoChange(this.props.num, 'titleID', data.value as number) : null}
                  className='transparent'
                  width={8}
                />
                <Form.Field
                  control={Select}
                  label='Instructor'
                  name='instructorID'
                  options={options.instructors}
                  value={info.instructorID}
                  onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'instructorID', data.value as number)}
                  width={8}
                  className={this.props.print ? 'transparent' : ''}
                  search
                  selection
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Select}
                  label='Funding Source'
                  name='fundingSource'
                  options={options.funding}
                  value={info.fundingSource}
                  onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'fundingSource', data.value as number)}
                  width={8}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Input}
                  label='Capacity'
                  name='capacity'
                  width={4}
                  value={info.capacity||''}
                  onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'capacity', data.value.validate())}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Input}
                  label='Required Hours'
                  name='requiredHours'
                  width={4}
                  value={info.requiredHours||''}
                  onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'requiredHours', data.value.validate())}
                  className={this.props.print ? 'transparent' : ''}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={DatePicker}
                  label="Date Begins"
                  name="dateBegins"
                  placeholder="MM/DD/YYYY"
                  selected={info.dateBegins ? new Date(info.dateBegins) : null}
                  onChange={(date:any) => this.props.infoChange(this.props.num, 'dateBegins', date ? format(date, 'MM/dd/yyyy') : null)}
                  width={4}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={DatePicker}
                  label="Date Ends"
                  name="dateEnds"
                  placeholder="MM/DD/YYYY"
                  selected={info.dateEnds ? new Date(info.dateEnds) : null}
                  onChange={(date:any) => this.props.infoChange(this.props.num, 'dateEnds', date ? format(date, 'MM/dd/yyyy') : null)}
                  width={4}
                  className={this.props.print ? 'transparent' : ''}
                />
              </Form.Group>
              {this.props.print ? null : <Fragment>
                <Button positive content='Save' onClick={_.debounce(() => this.props.saveInfo(this.props.num), 10000, true)} style={{marginBottom:20}} />
                {this.props.class.data.active ? <Button color='black' content='Archive' onClick={() => this.props.toggleArchiveModal(this.props.num)} style={{marginBottom:20}} /> : null}
                {!this.props.class.data.active ? <Button color='blue' content='Restore' onClick={() => this.props.toggleRestoreModal(this.props.num)} style={{marginBottom:20}} /> : null}
                {(this.props.class.data.active && !this.props.class.hasAttn) ? <Button color='orange' content='Re-Date' onClick={() => this.props.toggleDateModal(this.props.num)} style={{marginBottom:20,marginLeft:66}} /> : null}
              </Fragment>}
            </Form>
            <Divider />
            <Header as='h3'>Options</Header>
            <Form>
              <Grid>
                <Grid.Column width={8}>
                  {info.facilitySex == "M" || info.facilitySex == "F" ? null : <Form.Group>
                    <Form.Field label="Gender:" width={10}/>
                    <Radio name="sex" label="M" value="M" checked={info.sex === "M"} onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'sex', data.value as string)} style={{marginRight: 10}}  />
                    <Radio name="sex" label="F" value="F" checked={info.sex === "F"} onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'sex', data.value as string)} width={2} />
                  </Form.Group>}
                  <Form.Group>
                    <Form.Field width={8} label="Display on transcript" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="transcript"
                        checked={info.transcript}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'transcript', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Include class on movement list" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="movementList"
                        checked={info.movementList}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'movementList', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Include class on signin list" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="signinList"
                        checked={info.signinList}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'signinList', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Meet on holiday" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="meetOnHoliday"
                        checked={info.meetOnHoliday}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'meetOnHoliday', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Meet on furlough day" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="meetOnFurloughDay"
                        checked={info.meetOnFurloughDay}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'meetOnFurloughDay', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Title 1 class" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="title1"
                        checked={info.title1}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'title1', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="ABE class" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="abe"
                        checked={info.abe}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'abe', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Accumulate hours" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="accumulateHours"
                        checked={info.accumulateHours}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'accumulateHours', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  {/*<Form.Group>
                    <Form.Field width={8} label="Vocational" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="vocational"
                        checked={info.vocational}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'vocational', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>*/}
                  <Form.Group>
                    <Form.Field width={8} label="Online" />
                    <Form.Field>
                      <Checkbox
                        name='online'
                        checked={info.online}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'online', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="DoE" />
                    <Popup content={`This option must be selected to make DOE Enrollment Forms available to the Roster.`} position="top left" trigger={<Form.Field width={2}>
                      <Checkbox
                        name="doe"
                        checked={info.doe}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'doe', data.checked as boolean)}
                      />
                    </Form.Field>} />
                  </Form.Group>
                  {info.doe ? <Form.Group>
                    <Form.Field
                      control={Select}
                      label='DoE Facility'
                      name='doeFacilityID'
                      options={this.props.class.options.facilities}
                      value={info.doeFacilityID}
                      onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'doeFacilityID', data.value as number)}
                    />
                  </Form.Group> : null}
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Group>
                    <Form.Field width={8} label="Correspondence" />
                    <Form.Field>
                      <Checkbox
                        name='online'
                        checked={info.correspondence}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'correspondence', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Post Secondary" />
                    <Form.Field>
                      <Checkbox
                        name='online'
                        checked={info.postSecondary}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'postSecondary', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Workshop" />
                    <Form.Field>
                      <Checkbox
                        name='workshop'
                        checked={info.workshop}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'workshop', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Workforce Development Diploma" />
                    <Form.Field>
                      <Checkbox
                        name='online'
                        checked={info.workforceDiploma}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'workforceDiploma', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Ignore diploma/HSE requirement" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="ignoreEdStatus"
                        checked={info.ignoreEdStatus}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'ignoreEdStatus', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Ignore hours requirement" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="ignoreHoursRqt"
                        checked={info.ignoreHoursRqt}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'ignoreHoursRqt', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="One time only" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="oneTimeOnly"
                        checked={info.oneTimeOnly}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'oneTimeOnly', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Ignore Conflicts" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="skipConflicts"
                        checked={info.skipConflicts}
                        onChange={(e:any, data:any) => { if (this.props.admin) this.props.infoChange(this.props.num, 'skipConflicts', data.checked as boolean)}}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="No grade" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="noGrade"
                        checked={info.noGrade}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'noGrade', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="No attendance" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="noAttendance"
                        checked={info.noAttendance}
                        onChange={(e:any, data:any) => this.props.infoChange(this.props.num, 'noAttendance', data.checked as boolean)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} label="Approval Required" />
                    <Form.Field width={2}>
                      <Checkbox
                        name="approvalRequired"
                        checked={info.approvalRequired}
                        onChange={(e:any, data:any) => { if (this.props.admin) this.props.infoChange(this.props.num, 'approvalRequired', data.checked as boolean)}}
                      />
                    </Form.Field>
                  </Form.Group>
                  {info.approvalRequired ? <Form.Group>
                    <Form.Field
                      control={Input}
                      label="After X Classes"
                      placeholder="0"
                      name="maxRepeats"
                      value={info.maxRepeats||''}
                      onChange={(e:any, data:any) => { if (this.props.admin) this.props.infoChange(this.props.num, 'maxRepeats', data.value.validateNum() as string) }}
                      width={8}
                    />
                  </Form.Group> : null}
                </Grid.Column>
                {this.props.print ? null : <Grid.Column style={{paddingTop:0}}>
                  <Button positive content='Save' onClick={_.debounce(() => this.props.saveOptions(this.props.num), 10000, true)} style={{marginBottom:20}} />
                </Grid.Column>}
              </Grid>
            </Form>
            {!this.props.print || (this.props.hide ? schedules.length > 0 : true) ? <Fragment>
              <Divider />
              <Header as='h3'>Schedule</Header>
              <Table compact celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1} />
                    <Table.HeaderCell>Instructor</Table.HeaderCell>
                    <Table.HeaderCell>Day</Table.HeaderCell>
                    <Table.HeaderCell>Start</Table.HeaderCell>
                    <Table.HeaderCell>End</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Location</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {schedules.map((schedule:any, s:number) => (
                    <Fragment key={schedule.recordID}>
                      <Table.Row>
                        <Table.Cell warning={schedule.grouped.length > 0} style={{borderTop: schedule.grouped.length > 0 && (s == 0 || schedules[s-1].grouped == 0) ? '2px solid rgba(34, 36, 38, 0.1)' : '1px solid rgba(34, 36, 38, 0.1)'}}>
                          <Checkbox checked={schedule.group} onClick={() => this.props.groupSchedule(this.props.num, schedule.recordID)} />
                          {schedule.capacityPercent >= 0.8 && schedule.capacityPercent < 1 ? <Popup content="Class is close to capacity" position="top center" trigger={<Icon name="hourglass half" color="red" style={{verticalAlign:'top',marginTop:3,marginLeft:6}} />} /> : null}
                          {schedule.capacityPercent >= 1 ? <Popup content="Class is at capacity" position="top center" trigger={<Icon name="hourglass full" color="red" style={{verticalAlign:'top',marginTop:3,marginLeft:6}} />} /> : null}
                        </Table.Cell>
                        <Table.Cell warning={schedule.grouped.length > 0} style={{borderTop: schedule.grouped.length > 0 && (s == 0 || schedules[s-1].grouped == 0) ? '2px solid rgba(34, 36, 38, 0.1)' : '1px solid rgba(34, 36, 38, 0.1)'}}>{schedule.instructor}</Table.Cell>
                        <Table.Cell warning={schedule.grouped.length > 0} style={{borderTop: schedule.grouped.length > 0 && (s == 0 || schedules[s-1].grouped == 0) ? '2px solid rgba(34, 36, 38, 0.1)' : '1px solid rgba(34, 36, 38, 0.1)'}}>{_.find(days, (day:any) => day.value == schedule.dayOfWeek).text}</Table.Cell>
                        <Table.Cell warning={schedule.grouped.length > 0} style={{borderTop: schedule.grouped.length > 0 && (s == 0 || schedules[s-1].grouped == 0) ? '2px solid rgba(34, 36, 38, 0.1)' : '1px solid rgba(34, 36, 38, 0.1)'}}>{schedule.startTime}</Table.Cell>
                        <Table.Cell warning={schedule.grouped.length > 0} style={{borderTop: schedule.grouped.length > 0 && (s == 0 || schedules[s-1].grouped == 0) ? '2px solid rgba(34, 36, 38, 0.1)' : '1px solid rgba(34, 36, 38, 0.1)'}}>{schedule.endTime}</Table.Cell>
                        <Table.Cell warning={schedule.grouped.length > 0} style={{borderTop: schedule.grouped.length > 0 && (s == 0 || schedules[s-1].grouped == 0) ? '2px solid rgba(34, 36, 38, 0.1)' : '1px solid rgba(34, 36, 38, 0.1)'}}>{schedule.startDate}</Table.Cell>
                        <Table.Cell warning={schedule.grouped.length > 0} style={{borderTop: schedule.grouped.length > 0 && (s == 0 || schedules[s-1].grouped == 0) ? '2px solid rgba(34, 36, 38, 0.1)' : '1px solid rgba(34, 36, 38, 0.1)'}}>{schedule.location}</Table.Cell>
                        <Table.Cell warning={schedule.grouped.length > 0} style={{borderTop: schedule.grouped.length > 0 && (s == 0 || schedules[s-1].grouped == 0) ? '2px solid rgba(34, 36, 38, 0.1)' : '1px solid rgba(34, 36, 38, 0.1)'}}>{this.props.print ? null : <Button size="mini" color="green" content="Edit" onClick={() => this.props.openSchedule(this.props.num, schedule)} />}</Table.Cell>
                      </Table.Row>
                    </Fragment>
                  ))}
                </Table.Body>
              </Table>
              {this.props.print ? null : <Button positive content='Add' onClick={() => this.props.openAddSchedule(this.props.num)} />}
              {this.props.print || !this.props.class.canGroup ? null : <Popup content="GROUP is used to group together overlapping class sessions on the same day so that they appear together on the printed schedule." position="bottom center" trigger={<Button color='blue' content='Group' onClick={_.debounce(() => this.props.groupSchedules(this.props.num), 10000, true)} />} />}
              {this.props.print || !this.props.class.canGroup ? null : <Popup content="UNGROUP removes a class session from a GROUP. If the class sessions overlap is no longer there due to removal of a session or a change in the day and/or time of the session then a GROUP will UNGROUP automatically." position="bottom center" trigger={<Button color='black' content='Ungroup' onClick={_.debounce(() => this.props.ungroupSchedules(this.props.num), 10000, true)} />} />}
            </Fragment> : null}
            {!this.props.print || (this.props.hide ? hiatusList.length > 0 : true) ? <Fragment>
              <Divider />
              <Header as='h3'>Hiatus</Header>
              <Table compact celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Start Date</Table.HeaderCell>
                    <Table.HeaderCell>End Date</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {hiatusList.map((hiatus:any) => (
                    <Table.Row key={hiatus.recordID}>
                      <Table.Cell><Form><Form.Field control={DatePicker} selected={hiatus.startDate ? new Date(hiatus.startDate) : null} onChange={(date:any) => this.props.hiatusChange(this.props.num, hiatus.recordID, 'startDate', date ? format(date, 'MM/dd/yyyy') : null)} /></Form></Table.Cell>
                      <Table.Cell><Form><Form.Field control={DatePicker} selected={hiatus.endDate ? new Date(hiatus.endDate) : null} onChange={(date:any) => this.props.hiatusChange(this.props.num, hiatus.recordID, 'endDate', date ? format(date, 'MM/dd/yyyy') : null)} /></Form></Table.Cell>
                      <Table.Cell>{this.props.print ? null : <Button size="mini" color="red" content="-" onClick={_.debounce(() => this.props.removeHiatus(this.props.num, hiatus.recordID), 10000, true)} />}</Table.Cell>
                    </Table.Row>
                  ))}
                  <Table.Row>
                    <Table.Cell><Form><Form.Field control={DatePicker} selected={newHiatus.startDate ? new Date(newHiatus.startDate) : null} onChange={(date:any) => this.props.newHiatusChange(this.props.num, 'startDate', date ? format(date, 'MM/dd/yyyy') : null)} /></Form></Table.Cell>
                    <Table.Cell><Form><Form.Field control={DatePicker} selected={newHiatus.endDate ? new Date(newHiatus.endDate) : null} onChange={(date:any) => this.props.newHiatusChange(this.props.num, 'endDate', date ? format(date, 'MM/dd/yyyy') : null)} /></Form></Table.Cell>
                    <Table.Cell>{this.props.print ? null : <Button size="mini" color="green" content="+" onClick={_.debounce(() => this.props.addHiatus(this.props.num), 10000, true)} />}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              {this.props.print ? null : <Button positive content='Save' onClick={_.debounce(() => this.props.saveHiatus(this.props.num), 10000, true)} />}
            </Fragment> : null}
            <Modal
              open={this.props.class.modals.editInstructor.open}
              onClose={() => this.props.toggleEditInstructorModal(this.props.num)}
              size="small"
            >
              <Modal.Header content={`Edit Instructor`} />
              <Modal.Content>
                <Label color='yellow' style={{marginBottom:20}}>This class will not be printed on schedules until a default instructor is set.</Label>
                <Form>
                  <Form.Group>
                    <Form.Field
                      control={Select}
                      label="Default Instructor"
                      name="defaultInstructorID"
                      options={options.instructors}
                      value={this.props.class.modals.editInstructor.data.defaultInstructorID}
                      onChange={(e:any, data:any) => this.props.instructorChange(this.props.num, 'defaultInstructorID', data.value as number)}
                      width={8}
                    />
                  </Form.Group>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleEditInstructorModal(this.props.num)}>Back</Button>
                <Button
                  positive
                  content="Save"
                  onClick={() => this.props.class.modals.schedule.open ? this.props.saveSchedule(this.props.num, true) : this.props.addSchedule(this.props.num, true)}
                />
              </Modal.Actions>
            </Modal>
            <Modal
              open={this.props.class.modals.schedule.open}
              onClose={() => this.props.toggleScheduleModal(this.props.num)}
              size="small"
            >
              <Modal.Header content={`Edit Schedule`} />
              <Modal.Content>
                <Form>
                  <Form.Group>
                    <Form.Field
                      control={Select}
                      label="Instructor"
                      name="instructorID"
                      options={options.instructors}
                      value={this.props.class.modals.schedule.data.instructorID || info.instructorID}
                      onChange={(e:any, data:any) => this.props.scheduleChange(this.props.num, 'instructorID', data.value as number)}
                      width={5}
                    />
                    <Form.Field
                      control={Select}
                      label="Day of Week"
                      name="dayOfWeek"
                      options={days}
                      value={this.props.class.modals.schedule.data.dayOfWeek}
                      onChange={(e:any, data:any) => this.props.scheduleChange(this.props.num, 'dayOfWeek', data.value as number)}
                      width={6}
                    />
                    <Form.Field
                      control={Select}
                      label="Location"
                      name="locationID"
                      options={options.locations}
                      value={this.props.class.modals.schedule.data.locationID}
                      onChange={(e:any, data:any) => this.props.scheduleChange(this.props.num, 'locationID', data.value as number)}
                      width={5}
                    />
                  </Form.Group>
                  <Form.Group>
                    <div className='four wide field'>
                      <label>Start Time</label>
                      <Popup content="Specify time in military time (3PM = 15:00)" position="bottom center" trigger={<Cleave
                        name='startTime'
                        placeholder='15:30'
                        value={this.props.class.modals.schedule.data.startTime}
                        onChange={(e:any) => this.props.scheduleChange(this.props.num, 'startTime', e.target.value as string)}
                        options={{
                          time: true,
                          timePattern: ['h','m']
                        }}
                      />} />
                    </div>
                    <div className='four wide field'>
                      <label>End Time</label>
                      <Popup content="Specify time in military time (3PM = 15:00)" position="bottom center" trigger={<Cleave
                        name='endTime'
                        placeholder='17:00'
                        value={this.props.class.modals.schedule.data.endTime}
                        onChange={(e:any) => this.props.scheduleChange(this.props.num, 'endTime', e.target.value as string)}
                        options={{
                          time: true,
                          timePattern: ['h','m']
                        }}
                      />} />
                    </div>
                    <Popup content="If the session start date does not match the day, the next date that matches the day will be used." position="bottom center" trigger={<Form.Field
                      control={DatePicker}
                      label="Start Date"
                      name="startDate"
                      selected={this.props.class.modals.schedule.data.startDate ? new Date(this.props.class.modals.schedule.data.startDate) : null}
                      onChange={(date:any) => this.props.scheduleChange(this.props.num, 'startDate', date ? format(date, 'MM/dd/yyyy') : null)}
                      filterDate={(date:any) => getDay(date) == this.props.class.modals.schedule.data.dayOfWeek && date >= new Date(info.dateBegins) && date <= new Date(info.dateEnds)}
                      width={4}
                    />} />
                    <Popup content="Leave blank for default" position="bottom center" trigger={<Form.Field
                      control={Input}
                      label="Capacity"
                      name="capacity"
                      placeholder={info.capacity}
                      value={this.props.class.modals.schedule.data.capacity||''}
                      onChange={(e:any, data:any) => this.props.scheduleChange(this.props.num, 'capacity', data.value.validateNum() as string)}
                      width={4}
                    />} />
                  </Form.Group>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleScheduleModal(this.props.num)}>Close</Button>
                <Button
                  negative
                  content="Remove"
                  onClick={() => this.props.removeSchedule(this.props.num, this.props.class.modals.schedule.data.recordID)}
                />
                <Button
                  positive
                  content="Save"
                  onClick={() => this.props.saveSchedule(this.props.num)}
                />
              </Modal.Actions>
            </Modal>
            <Modal
              open={this.props.class.modals.addSchedule.open}
              onClose={() => this.props.toggleAddScheduleModal(this.props.num)}
              size="small"
            >
              <Modal.Header content={`Add Schedule`} />
              <Modal.Content>
                <Form>
                  <Form.Group>
                    <Form.Field
                      control={Select}
                      label="Instructor"
                      name="instructorID"
                      options={options.instructors}
                      value={this.props.class.modals.addSchedule.data.instructorID || info.instructorID}
                      onChange={(e:any, data:any) => this.props.newScheduleChange(this.props.num, 'instructorID', data.value as number)}
                      width={5}
                    />
                    <Form.Field
                      control={Select}
                      label="Day of Week"
                      name="dayOfWeek"
                      options={days}
                      value={this.props.class.modals.addSchedule.data.dayOfWeek}
                      onChange={(e:any, data:any) => this.props.newScheduleChange(this.props.num, 'dayOfWeek', data.value as number)}
                      width={6}
                    />
                    <Form.Field
                      control={Select}
                      label="Location"
                      name="locationID"
                      options={options.locations}
                      value={this.props.class.modals.addSchedule.data.locationID}
                      onChange={(e:any, data:any) => this.props.newScheduleChange(this.props.num, 'locationID', data.value as number)}
                      width={5}
                    />
                  </Form.Group>
                  <Form.Group>
                    <div className='four wide field'>
                      <label>Start Time</label>
                      <Popup content="Specify time in military time (3PM = 15:00)" position="bottom center" trigger={<Cleave
                        name='startTime'
                        placeholder='15:30'
                        value={this.props.class.modals.addSchedule.data.startTime}
                        onChange={(e:any) => this.props.newScheduleChange(this.props.num, 'startTime', e.target.value as string)}
                        options={{
                          time: true,
                          timePattern: ['h','m']
                        }}
                      />} />
                    </div>
                    <div className='four wide field'>
                      <label>End Time</label>
                      <Popup content="Specify time in military time (3PM = 15:00)" position="bottom center" trigger={<Cleave
                        name='endTime'
                        placeholder='17:00'
                        value={this.props.class.modals.addSchedule.data.endTime}
                        onChange={(e:any) => this.props.newScheduleChange(this.props.num, 'endTime', e.target.value as string)}
                        options={{
                          time: true,
                          timePattern: ['h','m']
                        }}
                      />} />
                    </div>
                    <Popup content="If the session start date does not match the day, the next date that matches the day will be used." position="bottom center" trigger={<Form.Field
                      control={DatePicker}
                      label="Start Date"
                      name="startDate"
                      selected={this.props.class.modals.addSchedule.data.startDate ? new Date(this.props.class.modals.addSchedule.data.startDate) : null}
                      onChange={(date:any) => this.props.newScheduleChange(this.props.num, 'startDate', date ? format(date, 'MM/dd/yyyy') : null)}
                      filterDate={(date:any) => getDay(date) == this.props.class.modals.addSchedule.data.dayOfWeek && date >= new Date(info.dateBegins) && date <= new Date(info.dateEnds)}
                      width={4}
                    />} />
                    <Popup content="Leave blank for default" position="bottom center" trigger={<Form.Field
                      control={Input}
                      label="Capacity"
                      name="capacity"
                      placeholder={info.capacity}
                      value={this.props.class.modals.addSchedule.data.capacity}
                      onChange={(e:any, data:any) => this.props.newScheduleChange(this.props.num, 'capacity', data.value.validateNum() as string)}
                      width={4}
                    />} />
                  </Form.Group>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleAddScheduleModal(this.props.num)}>Close</Button>
                <Button
                  positive
                  content="Add"
                  onClick={() => this.props.addSchedule(this.props.num)}
                />
              </Modal.Actions>
            </Modal>
            <Modal
              open={this.props.class.modals.archive.open}
              onClose={() => this.props.toggleArchiveModal(this.props.num)}
              size="mini"
            >
              <Modal.Header content={`Archive ${this.props.class.data.subTitle}`} />
              <Modal.Content>
                <div>Are you sure you wish to <b>archive</b> this class?</div>
                {this.props.class.waitlists.length > 0 ? <div>Note that inmates <b>still exist</b> on the waitlist.</div> : null}
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleArchiveModal(this.props.num)}>Close</Button>
                <Button
                  positive
                  content="Archive"
                  onClick={() => this.props.archiveClass(this.props.num)}
                />
              </Modal.Actions>
            </Modal>
            <Modal
              open={this.props.class.modals.restore.open}
              onClose={() => this.props.toggleRestoreModal(this.props.num)}
              size="mini"
            >
              <Modal.Header content={`Restore ${this.props.class.data.subTitle}`} />
              <Modal.Content>
                <div>Are you sure you wish to <b>restore</b> this class?</div>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleRestoreModal(this.props.num)}>Close</Button>
                <Button
                  positive
                  content="Restore"
                  onClick={() => this.props.restoreClass(this.props.num)}
                />
              </Modal.Actions>
            </Modal>
            <Modal
              open={this.props.class.modals.date.open}
              onClose={() => this.props.toggleDateModal(this.props.num)}
              size="tiny"
            >
              <Modal.Header content={`Re-Date ${this.props.class.data.subTitle}`} />
              <Modal.Content>
                <Form>
                  <Form.Group>
                    <Form.Field
                      control={DatePicker}
                      label="New Date Begins"
                      name="dateBegins"
                      placeholder="MM/DD/YYYY"
                      selected={this.props.class.modals.date.data.date ? new Date(this.props.class.modals.date.data.date) : null}
                      onChange={(date:any) => this.props.updateClassDate(this.props.num, 'date', date ? format(date, 'MM/dd/yyyy') : null)}
                      includeDates={this.dates()}
                      width={8}
                      className={this.props.print ? 'transparent' : ''}
                    />
                    <Form.Field
                      control={Checkbox}
                      label="Update roster start dates by difference"
                      name="move"
                      checked={this.props.class.modals.date.data.move}
                      onChange={() => this.props.updateClassDate(this.props.num, 'move', !this.props.class.modals.date.data.move)}
                      style={{marginTop:28}}
                    />
                  </Form.Group>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleDateModal(this.props.num)}>Close</Button>
                <Button
                  positive
                  content="Save"
                  onClick={() => this.props.reDate(this.props.num)}
                />
              </Modal.Actions>
            </Modal>
            <Modal
              open={this.props.class.modals.sync.open}
              onClose={() => this.props.toggleSyncModal(this.props.num)}
              size="tiny"
            >
              <Modal.Header content={`Schedule Sync`} />
              <Modal.Content>
                <Container fluid>
                  {this.props.class.modals.sync.data.scheduleID ? `You have modified the start date of this schedule.` : `You have modified the dates of this class.`} You may update any relevant schedule start dates below.
                </Container>
                <Divider />
                {this.props.class.modals.sync.data.scheduleID ? <Tab menu={{ secondary: true, pointing: true }} panes={schedulePanes} activeIndex={this.props.class.modals.sync.data.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchSyncTab(this.props.num, activeIndex) }} /> : <Tab menu={{ secondary: true, pointing: true }} panes={classPanes} activeIndex={this.props.class.modals.sync.data.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchSyncTab(this.props.num, activeIndex) }} />}
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleSyncModal(this.props.num)}>Close</Button>
                <Button
                  positive
                  content="Save"
                  onClick={() => this.props.syncSchedules(this.props.num)}
                />
              </Modal.Actions>
            </Modal>
            <Modal
              open={this.props.class.modals.capSync.open}
              onClose={() => this.props.toggleCapSyncModal(this.props.num)}
              size="tiny"
            >
              <Modal.Header content={`Capacity Sync`} />
              <Modal.Content>
                <Container fluid>
                  {this.props.class.modals.capSync.data.scheduleID ? `You have modified the capacity of this schedule.` : `You have modified the capacity of this class.`} You may update any relevant capacities below. Leave session capacity field blank for default.
                </Container>
                <Divider />
                <ClassSyncCapacities class={this.props.class} num={this.props.num} />
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleCapSyncModal(this.props.num)}>Close</Button>
                <Button
                  positive
                  content="Save"
                  onClick={() => this.props.syncCapacities(this.props.num)}
                />
              </Modal.Actions>
            </Modal>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, print: ownProps.print, hide: ownProps.hide, admin: state.staff.staff ? state.staff.staff.admin : false } },
  PanesStore.actionCreators
)(ClassData as any)
