import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format, addDays, addYears } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Icon,
  Form,
  Button,
  Dropdown,
  Input,
  Select,
  TextArea,
  Grid,
  Header,
  Popup,
  Divider,
  Table,
  Label
} from 'semantic-ui-react'
const _ = require('underscore')
const pluralize = require('pluralize')

type PaneProps =
    { career: PanesStore.Career, num: 1 | 2, print: boolean, mode: string } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

type optionFields = 'sex'|'race'|'ethnicity'|'facility'

class CareerPane extends React.PureComponent<PaneProps> {
    private displayTrait = (trait:any) => {
        switch (trait.kind) {
        case 'date':
          let display = trait.field.capitalize()
          if (trait.field == 'dob') { display = "DoB" }
          if (trait.startDate == trait.endDate) {
            return `${display}: ${trait.startDate}`
          } else if (trait.endDate == null) {
              return `${display}: > ${trait.startDate}`
          } else if (trait.startDate == null) {
              return `${display}: < ${trait.endDate}`
          } else {
              return `${display}: ${trait.startDate} - ${trait.endDate}`
          }
        case 'numeric':
          if (trait.min == trait.max) {
            return `${trait.field.capitalize()}: ${trait.min}`
          } else if (trait.max == null) {
              return `${trait.field.capitalize()}: > ${trait.min}`
          } else if (trait.min == null) {
              return `${trait.field.capitalize()}: < ${trait.max}`
          } else {
              return `${pluralize(trait.field).capitalize()}: ${trait.min} - ${trait.max}`
          }
        case 'option':
            var values = _.map(trait.value, (key:string) => _.find(this.props.career.options[trait.field as optionFields], (opt:any) => opt.value == key).text)
            if (values.length > 1) {
                return `${pluralize(trait.field).capitalize()}: ${values.join(', ')}`
            } else {
                return `${trait.field.capitalize()}: ${values.join(', ')}`
            }
        }
    }

    public render() {
        return (
          <Container fluid>
            {this.props.print ? null : <div className='close' style={{position:'absolute',top:0,left:0,zIndex:1}}>
              <Button circular color='red' icon='close' size='mini' onClick={() => this.props.togglePane(this.props.num)} />
            </div>}
            <Button color='blue' circular icon='print' onClick={() => this.props.togglePrint(this.props.num)} style={{float:'right',fontSize:'1.2em',marginTop:15}} />
            <Grid style={{marginBottom:10}}>
              <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column>
                  <Header as='h2' style={{display:'inline-block',marginTop:0,width:750,whiteSpace:'normal'}}><div><Icon name='suitcase' /> {this.props.career.data.name}</div></Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Header as='h3'>Info</Header>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label='Name'
                  value={this.props.career.info.name||''}
                  onChange={(e:any, data:any) => this.props.editCareerField(this.props.num, 'name', data.value.validate())}
                  width={12}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Select}
                  label='Code'
                  className={this.props.print ? 'transparent' : ''}
                  width={4}
                  value={this.props.career.info.code ? this.props.career.info.code.split('') : []}
                  onChange={(e:any, d:any) => {
                    if (d.value.length <= 3) {
                      this.props.editCareerField(this.props.num, 'code', d.value.sort().join(''))
                    }
                  }}
                  options={[
                    { key: 'R', text: 'R', value: 'R' },
                    { key: 'I', text: 'I', value: 'I' }, 
                    { key: 'A', text: 'A', value: 'A' },
                    { key: 'S', text: 'S', value: 'S' },
                    { key: 'E', text: 'E', value: 'E' },
                    { key: 'C', text: 'C', value: 'C' }
                  ]}
                  multiple
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={TextArea}
                  label='Narrative'
                  value={this.props.career.info.narrative||''}
                  onChange={(e:any, data:any) => this.props.editCareerField(this.props.num, 'narrative', data.value.validate())}
                  className={this.props.print ? 'transparent' : ''}
                  width={16}
                  rows={3}
                />
              </Form.Group>
              <Grid columns={1} style={{marginTop:10}}>
                <Grid.Row>
                  <Grid.Column>
                    <Header as='h3'>Prerequisites</Header>
                    <Form.Field
                      control={Select}
                      placeholder='Classes'
                      options={_.map(this.props.career.options.templates, (template:any) => { return { ...template, content: <Fragment><div style={{padding:'.78571429rem 1.14285714rem',backgroundColor:(_.any(_.filter(this.props.career.info.templates, (template:any) => template.pre), (t:any) => t.recordID == template.key) ? '#fffaf3' : 'inherit')}}><span className='text' style={{display:'block'}}>{template.text}</span><span className='description' style={{display:'block',float:'none',color:'rgba(0,0,0,.4)',margin:0,marginTop:4}}>{template.category}</span></div></Fragment> } })}
                      value={null}
                      onChange={(e:any, data:any) => this.props.selectCareerPreTemplate(this.props.num, {key: data.value, data: data.options.find((opt:any) => opt.value === data.value)})}
                      className='nopad'
                      search
                    />
                    <Form.Group>
                      <Form.Field
                        control={Select}
                        placeholder='Traits'
                        options={[{ key:'',value:null,text:'' },{ key: 0, text: 'DoB', value: 'dob' },{ key: 1, text: 'Age', value: 'age' },{ key: 2, text: 'Sex', value: 'sex' },{ key: 3, text: 'Race', value: 'race' },{ key: 4, text: 'Ethnicity', value: 'ethnicity' },{ key: 5, text: 'Facility', value: 'facility' }]}
                        value={this.props.career.preTrait.field}
                        onChange={(e:any, data:any) => this.props.editCareerPreTrait(this.props.num, 'field', data.value as string)}
                        width={3}
                      />
                      {this.props.career.preTrait.kind == 'option' ? <Fragment>
                        <Form.Field
                          control={Select}
                          options={this.props.career.options[this.props.career.preTrait.field as optionFields]}
                          value={this.props.career.preTrait.value ?? []}
                          onChange={(e:any, data:any) => this.props.editCareerPreTrait(this.props.num, 'value', data.value)}
                          width={4}
                          multiple
                        />
                      </Fragment> : null}
                      {this.props.career.preTrait.kind == 'numeric' ? <Fragment>
                        <Form.Field
                          control={Select}
                          options={[ { key: 0, value: 'equal', text: 'Equals' }, { key: 1, value: 'greater', text: 'Greater than' }, { key: 2, value: 'less', text: 'Less than' }, { key: 3, value: 'range', text: 'Ranges' } ]}
                          value={this.props.career.preTrait.comparison}
                          onChange={(e:any, data:any) => this.props.editCareerPreTrait(this.props.num, 'comparison', data.value as string)}
                          width={3}
                        />
                        {this.props.career.preTrait.comparison == 'greater' || this.props.career.preTrait.comparison == 'equal' ? <Fragment>
                          <Form.Field
                            control={Select}
                            options={[ { key:'',value:null,text:'' }, ...Array.from({length: 100}, (_, i) => ({ key: i+1, value: i+1, text: (i+1).toString() })) ]}
                            value={this.props.career.preTrait.min}
                            onChange={(e:any, data:any) => this.props.editCareerPreTrait(this.props.num, 'min', data.value as number)}
                            width={3}
                          />
                        </Fragment> : null}
                        {this.props.career.preTrait.comparison == 'less' ? <Fragment>
                          <Form.Field
                            control={Select}
                            options={[ { key:'',value:null,text:'' }, ...Array.from({length: 100}, (_, i) => ({ key: i+1, value: i+1, text: (i+1).toString() })) ]}
                            value={this.props.career.preTrait.max}
                            onChange={(e:any, data:any) => this.props.editCareerPreTrait(this.props.num, 'max', data.value as number)}
                            width={3}
                          />
                        </Fragment> : null}
                        {this.props.career.preTrait.comparison == 'range' ? <Fragment>
                          <Form.Field
                            placeholder="From"
                            control={Select}
                            options={[ { key:'',value:null,text:'' }, ...Array.from({length: 100}, (_, i) => ({ key: i+1, value: i+1, text: (i+1).toString() })) ]}
                            value={this.props.career.preTrait.min}
                            onChange={(e:any, data:any) => this.props.editCareerPreTrait(this.props.num, 'min', data.value as number)}
                            width={3}
                          />
                          <Form.Field
                            placeholder="To"
                            control={Select}
                            options={[ { key:'',value:null,text:'' }, ...Array.from({length: 100}, (_, i) => ({ key: i+1, value: i+1, text: (i+1).toString() })) ]}
                            value={this.props.career.preTrait.max}
                            onChange={(e:any, data:any) => this.props.editCareerPreTrait(this.props.num, 'max', data.value as number)}
                            width={3}
                          />
                        </Fragment> : null}
                      </Fragment> : null}
                      {this.props.career.preTrait.kind == 'date' ? <Fragment>
                        <Form.Field
                          control={Select}
                          options={[ { key: 0, value: 'equal', text: 'Equals' }, { key: 1, value: 'greater', text: 'Greater than' }, { key: 2, value: 'less', text: 'Less than' }, { key: 3, value: 'range', text: 'Ranges' } ]}
                          value={this.props.career.preTrait.comparison}
                          onChange={(e:any, data:any) => this.props.editCareerPreTrait(this.props.num, 'comparison', data.value as string)}
                          width={3}
                        />
                        {this.props.career.preTrait.comparison == 'greater' || this.props.career.preTrait.comparison == 'equal' ? <Fragment>
                          <Form.Field
                            control={DatePicker}
                            selected={this.props.career.preTrait.startDate ? new Date(this.props.career.preTrait.startDate) : null}
                            onChange={(date:any) => this.props.editCareerPreTrait(this.props.num, 'startDate', date ? format(date, 'MM/dd/yyyy') : null)}
                          />
                        </Fragment> : null}
                        {this.props.career.preTrait.comparison == 'less' ? <Fragment>
                          <Form.Field
                            control={DatePicker}
                            selected={this.props.career.preTrait.endDate ? new Date(this.props.career.preTrait.endDate) : null}
                            onChange={(date:any) => this.props.editCareerPreTrait(this.props.num, 'endDate', date ? format(date, 'MM/dd/yyyy') : null)}
                          />
                        </Fragment> : null}
                        {this.props.career.preTrait.comparison == 'range' ? <Fragment>
                          <Form.Field
                            control={DatePicker}
                            selected={this.props.career.preTrait.startDate ? new Date(this.props.career.preTrait.startDate) : null}
                            onChange={(date:any) => this.props.editCareerPreTrait(this.props.num, 'startDate', date ? format(date, 'MM/dd/yyyy') : null)}
                          />
                          <Form.Field
                            control={DatePicker}
                            selected={this.props.career.preTrait.endDate ? new Date(this.props.career.preTrait.endDate) : null}
                            onChange={(date:any) => this.props.editCareerPreTrait(this.props.num, 'endDate', date ? format(date, 'MM/dd/yyyy') : null)}
                          />
                        </Fragment> : null}
                      </Fragment> : null}
                      {this.props.career.preTrait.field ? <Button icon='plus' color='green' circular style={{marginLeft:10,height:32}} onClick={() => this.props.addCareerPreTrait(this.props.num)} /> : null}
                    </Form.Group>
                    {this.props.career.preGroup.templates.length > 0 || this.props.career.preGroup.traits.length > 0 ? <Table compact celled striped style={{marginBottom:20}}>
                      <Table.Body>
                        {this.props.career.preGroup.templates.length + this.props.career.preGroup.traits.length > 1 ? <Table.Row positive>
                          <Table.Cell colSpan={2} style={{ fontWeight: 700, padding: '.5em' }}>{this.props.career.preGroup.gate}<Icon name='sync alternate' className='hover' style={{float:'right',marginLeft:10}} onClick={() => this.props.switchGate(this.props.num, -1)} /><Icon name='save' className='hover' style={{float:'right'}} onClick={() => this.props.savePreGroup(this.props.num)} /></Table.Cell>
                        </Table.Row> : null}
                        {_.map(this.props.career.preGroup.templates, (template:any, t:number) => <Table.Row key={t}>
                          <Table.Cell>
                            {this.props.mode == 'dual' ? <Popup
                              hideOnScroll
                              on='click'
                              trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block'}}>{template.name} | {template.program} -- {template.course}</div>}
                            >
                              <Popup.Header>Open in pane</Popup.Header>
                              <Popup.Content>
                                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(1, template.recordID) }}>1</Label>
                                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(2, template.recordID) }}>2</Label>
                              </Popup.Content>
                            </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block'}} onClick={() => { this.props.fetchTemplate(this.props.mode == 'left' ? 1 : 2, template.recordID) }}>{template.name} | {template.program} -- {template.course}</div>}
                          </Table.Cell>
                          <Table.Cell width={1}>
                            <Button color='red' size='mini' content='Remove' onClick={() => this.props.removeTemplate(this.props.num, -1, template.recordID)} />
                          </Table.Cell>
                        </Table.Row>)}
                        {_.map(this.props.career.preGroup.traits, (trait:any, t:number) => <Table.Row key={t}>
                          <Table.Cell>{this.displayTrait(trait)}</Table.Cell>
                          <Table.Cell width={1}>
                            <Button color='red' size='mini' content='Remove' onClick={() => this.props.removeTrait(this.props.num, -1, trait)} />
                          </Table.Cell>
                        </Table.Row>)}
                      </Table.Body>
                    </Table> : null}
                    {_.map(_.filter(this.props.career.info.groups, (group:any) => group.pre && (group.templates.length > 0 || group.traits.length > 0)), (group:any, g:number) => <Table compact celled striped style={{marginBottom:20}} key={g}>
                      <Table.Body>
                        {group.templates.length + group.traits.length > 1 ? <Table.Row positive>
                          <Table.Cell colSpan={2} style={{ fontWeight: 700, padding: '.5em' }}>{group.gate}<Icon name='sync alternate' className='hover' style={{float:'right'}} onClick={() => this.props.switchGate(this.props.num, group.recordID)} /></Table.Cell>
                        </Table.Row> : null}
                        {_.map(group.templates, (template:any, t:number) => <Table.Row key={t}>
                          <Table.Cell>
                            {this.props.mode == 'dual' ? <Popup
                              hideOnScroll
                              on='click'
                              trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block'}}>{template.name} | {template.program} -- {template.course}</div>}
                            >
                              <Popup.Header>Open in pane</Popup.Header>
                              <Popup.Content>
                                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(1, template.recordID) }}>1</Label>
                                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(2, template.recordID) }}>2</Label>
                              </Popup.Content>
                            </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block'}} onClick={() => { this.props.fetchTemplate(this.props.mode == 'left' ? 1 : 2, template.recordID) }}>{template.name} | {template.program} -- {template.course}</div>}
                          </Table.Cell>
                          <Table.Cell width={1}>
                            <Button color='red' size='mini' content='Remove' onClick={() => this.props.removeTemplate(this.props.num, group.recordID, template.recordID)} />
                          </Table.Cell>
                        </Table.Row>)}
                        {_.map(group.traits, (trait:any, t:number) => <Table.Row key={t}>
                          <Table.Cell>{this.displayTrait(trait)}</Table.Cell>
                          <Table.Cell width={1}>
                            <Button color='red' size='mini' content='Remove' onClick={() => this.props.removeTrait(this.props.num, group.recordID, trait)} />
                          </Table.Cell>
                        </Table.Row>)}
                      </Table.Body>
                    </Table>)}
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h3' style={{marginTop:30}}>Requisites</Header>
                    <Form.Field
                      control={Select}
                      placeholder='Classes'
                      options={_.map(this.props.career.options.templates, (template:any) => { return { ...template, content: <Fragment><div style={{padding:'.78571429rem 1.14285714rem',backgroundColor:(_.any(_.filter(this.props.career.info.templates, (template:any) => !template.pre), (t:any) => t.recordID == template.key) ? '#fffaf3' : 'inherit')}}><span className='text' style={{display:'block'}}>{template.text}</span><span className='description' style={{display:'block',float:'none',color:'rgba(0,0,0,.4)',margin:0,marginTop:4}}>{template.category}</span></div></Fragment> } })}
                      value={null}
                      onChange={(e:any, data:any) => this.props.selectCareerTemplate(this.props.num, {key: data.value, data: data.options.find((opt:any) => opt.value === data.value)})}
                      className='nopad'
                      search
                    />
                    <Form.Group>
                      <Form.Field
                        control={Select}
                        placeholder='Traits'
                        options={[{ key:'',value:null,text:'' },{ key: 0, text: 'DoB', value: 'dob' },{ key: 1, text: 'Age', value: 'age' },{ key: 2, text: 'Sex', value: 'sex' },{ key: 3, text: 'Race', value: 'race' },{ key: 4, text: 'Ethnicity', value: 'ethnicity' },{ key: 5, text: 'Facility', value: 'facility' }]}
                        value={this.props.career.trait.field}
                        onChange={(e:any, data:any) => this.props.editCareerTrait(this.props.num, 'field', data.value as string)}
                        width={3}
                      />
                      {this.props.career.trait.kind == 'option' ? <Fragment>
                        <Form.Field
                          control={Select}
                          options={this.props.career.options[this.props.career.trait.field as ('sex'|'race'|'ethnicity'|'facility')]}
                          value={this.props.career.trait.value ?? []}
                          onChange={(e:any, data:any) => this.props.editCareerTrait(this.props.num, 'value', data.value)}
                          width={4}
                          multiple
                        />
                      </Fragment> : null}
                      {this.props.career.trait.kind == 'numeric' ? <Fragment>
                        <Form.Field
                          control={Select}
                          options={[ { key: 0, value: 'equal', text: 'Equals' }, { key: 1, value: 'greater', text: 'Greater than' }, { key: 2, value: 'less', text: 'Less than' }, { key: 3, value: 'range', text: 'Ranges' } ]}
                          value={this.props.career.trait.comparison}
                          onChange={(e:any, data:any) => this.props.editCareerTrait(this.props.num, 'comparison', data.value as string)}
                          width={3}
                        />
                        {this.props.career.trait.comparison == 'greater' || this.props.career.trait.comparison == 'equal' ? <Fragment>
                          <Form.Field
                            control={Select}
                            options={[ { key:'',value:null,text:'' }, ...Array.from({length: 100}, (_, i) => ({ key: i+1, value: i+1, text: (i+1).toString() })) ]}
                            value={this.props.career.trait.min}
                            onChange={(e:any, data:any) => this.props.editCareerTrait(this.props.num, 'min', data.value as number)}
                            width={3}
                          />
                        </Fragment> : null}
                        {this.props.career.trait.comparison == 'less' ? <Fragment>
                          <Form.Field
                            control={Select}
                            options={[ { key:'',value:null,text:'' }, ...Array.from({length: 100}, (_, i) => ({ key: i+1, value: i+1, text: (i+1).toString() })) ]}
                            value={this.props.career.trait.max}
                            onChange={(e:any, data:any) => this.props.editCareerTrait(this.props.num, 'max', data.value as number)}
                            width={3}
                          />
                        </Fragment> : null}
                        {this.props.career.trait.comparison == 'range' ? <Fragment>
                          <Form.Field
                            placeholder="From"
                            control={Select}
                            options={[ { key:'',value:null,text:'' }, ...Array.from({length: 100}, (_, i) => ({ key: i+1, value: i+1, text: (i+1).toString() })) ]}
                            value={this.props.career.trait.min}
                            onChange={(e:any, data:any) => this.props.editCareerTrait(this.props.num, 'min', data.value as number)}
                            width={3}
                          />
                          <Form.Field
                            placeholder="To"
                            control={Select}
                            options={[ { key:'',value:null,text:'' }, ...Array.from({length: 100}, (_, i) => ({ key: i+1, value: i+1, text: (i+1).toString() })) ]}
                            value={this.props.career.trait.max}
                            onChange={(e:any, data:any) => this.props.editCareerTrait(this.props.num, 'max', data.value as number)}
                            width={3}
                          />
                        </Fragment> : null}
                      </Fragment> : null}
                      {this.props.career.trait.kind == 'date' ? <Fragment>
                        <Form.Field
                          control={Select}
                          options={[ { key: 0, value: 'equal', text: 'Equals' }, { key: 1, value: 'greater', text: 'Greater than' }, { key: 2, value: 'less', text: 'Less than' }, { key: 3, value: 'range', text: 'Ranges' } ]}
                          value={this.props.career.trait.comparison}
                          onChange={(e:any, data:any) => this.props.editCareerTrait(this.props.num, 'comparison', data.value as string)}
                          width={3}
                        />
                        {this.props.career.trait.comparison == 'greater' || this.props.career.trait.comparison == 'equal' ? <Fragment>
                          <Form.Field
                            control={DatePicker}
                            selected={this.props.career.trait.startDate ? new Date(this.props.career.trait.startDate) : null}
                            onChange={(date:any) => this.props.editCareerTrait(this.props.num, 'startDate', date ? format(date, 'MM/dd/yyyy') : null)}
                          />
                        </Fragment> : null}
                        {this.props.career.trait.comparison == 'less' ? <Fragment>
                          <Form.Field
                            control={DatePicker}
                            selected={this.props.career.trait.endDate ? new Date(this.props.career.trait.endDate) : null}
                            onChange={(date:any) => this.props.editCareerTrait(this.props.num, 'endDate', date ? format(date, 'MM/dd/yyyy') : null)}
                          />
                        </Fragment> : null}
                        {this.props.career.trait.comparison == 'range' ? <Fragment>
                          <Form.Field
                            control={DatePicker}
                            selected={this.props.career.trait.startDate ? new Date(this.props.career.trait.startDate) : null}
                            onChange={(date:any) => this.props.editCareerTrait(this.props.num, 'startDate', date ? format(date, 'MM/dd/yyyy') : null)}
                          />
                          <Form.Field
                            control={DatePicker}
                            selected={this.props.career.trait.endDate ? new Date(this.props.career.trait.endDate) : null}
                            onChange={(date:any) => this.props.editCareerTrait(this.props.num, 'endDate', date ? format(date, 'MM/dd/yyyy') : null)}
                          />
                        </Fragment> : null}
                      </Fragment> : null}
                      {this.props.career.trait.field ? <Button icon='plus' color='green' circular style={{marginLeft:10,height:32}} onClick={() => this.props.addCareerTrait(this.props.num)} /> : null}
                    </Form.Group>
                    {this.props.career.group.templates.length > 0 || this.props.career.group.traits.length > 0 ? <Table compact celled striped style={{marginBottom:20}}>
                      <Table.Body>
                        {this.props.career.group.templates.length + this.props.career.group.traits.length > 1 ? <Table.Row positive>
                          <Table.Cell colSpan={2} style={{ fontWeight: 700, padding: '.5em' }}>{this.props.career.group.gate}<Icon name='sync alternate' className='hover' style={{float:'right',marginLeft:10}} onClick={() => this.props.switchGate(this.props.num, 0)} /><Icon name='save' className='hover' style={{float:'right'}} onClick={() => this.props.saveGroup(this.props.num)} /></Table.Cell>
                        </Table.Row> : null}
                        {_.map(this.props.career.group.templates, (template:any, t:number) => <Table.Row key={t}>
                          <Table.Cell>
                            {this.props.mode == 'dual' ? <Popup
                              hideOnScroll
                              on='click'
                              trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block'}}>{template.name} | {template.program} -- {template.course}</div>}
                            >
                              <Popup.Header>Open in pane</Popup.Header>
                              <Popup.Content>
                                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(1, template.recordID) }}>1</Label>
                                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(2, template.recordID) }}>2</Label>
                              </Popup.Content>
                            </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block'}} onClick={() => { this.props.fetchTemplate(this.props.mode == 'left' ? 1 : 2, template.recordID) }}>{template.name} | {template.program} -- {template.course}</div>}
                          </Table.Cell>
                          <Table.Cell width={1}>
                            <Button color='red' size='mini' content='Remove' onClick={() => this.props.removeTemplate(this.props.num, 0, template.recordID)} />
                          </Table.Cell>
                        </Table.Row>)}
                        {_.map(this.props.career.group.traits, (trait:any, t:number) => <Table.Row key={t}>
                          <Table.Cell>{this.displayTrait(trait)}</Table.Cell>
                          <Table.Cell width={1}>
                            <Button color='red' size='mini' content='Remove' onClick={() => this.props.removeTrait(this.props.num, 0, trait)} />
                          </Table.Cell>
                        </Table.Row>)}
                      </Table.Body>
                    </Table> : null}
                    {_.map(_.filter(this.props.career.info.groups, (group:any) => !group.pre && (group.templates.length > 0 || group.traits.length > 0)), (group:any, g:number) => <Table compact celled striped style={{marginBottom:20}} key={g}>
                      <Table.Body>
                        {group.templates.length + group.traits.length > 1 ? <Table.Row positive>
                          <Table.Cell colSpan={2} style={{ fontWeight: 700, padding: '.5em' }}>{group.gate}<Icon name='sync alternate' className='hover' style={{float:'right'}} onClick={() => this.props.switchGate(this.props.num, group.recordID)} /></Table.Cell>
                        </Table.Row> : null}
                        {_.map(group.templates, (template:any, t:number) => <Table.Row key={t}>
                          <Table.Cell>
                            {this.props.mode == 'dual' ? <Popup
                              hideOnScroll
                              on='click'
                              trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block'}}>{template.name} | {template.program} -- {template.course}</div>}
                            >
                              <Popup.Header>Open in pane</Popup.Header>
                              <Popup.Content>
                                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(1, template.recordID) }}>1</Label>
                                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(2, template.recordID) }}>2</Label>
                              </Popup.Content>
                            </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block'}} onClick={() => { this.props.fetchTemplate(this.props.mode == 'left' ? 1 : 2, template.recordID) }}>{template.name} | {template.program} -- {template.course}</div>}
                          </Table.Cell>
                          <Table.Cell width={1}>
                            <Button color='red' size='mini' content='Remove' onClick={() => this.props.removeTemplate(this.props.num, group.recordID, template.recordID)} />
                          </Table.Cell>
                        </Table.Row>)}
                        {_.map(group.traits, (trait:any, t:number) => <Table.Row key={t}>
                          <Table.Cell>{this.displayTrait(trait)}</Table.Cell>
                          <Table.Cell width={1}>
                            <Button color='red' size='mini' content='Remove' onClick={() => this.props.removeTrait(this.props.num, group.recordID, trait)} />
                          </Table.Cell>
                        </Table.Row>)}
                      </Table.Body>
                    </Table>)}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Divider />
              {this.props.print ? null : <Button positive content='Save' loading={this.props.career.loading} onClick={_.debounce(() => this.props.saveCareer(this.props.num, 'career'), 10000, true)} />}
              {this.props.print ? null : <Button negative content='Remove' onClick={_.debounce(() => this.props.removeCareer(this.props.num), 10000, true)} />}
            </Form>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { career: ownProps.career, num: ownProps.num, print: ownProps.options.print, mode: state.panes.mode } },
  PanesStore.actionCreators
)(CareerPane as any)