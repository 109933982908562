import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { Link } from 'react-router-dom'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import {
  Container,
  Label,
  Accordion,
  Header,
  Popup,
  Table,
  Progress
} from 'semantic-ui-react'
const _ = require('underscore')
const pluralize = require('pluralize')

type InfoProps =
    { inmate: PanesStore.Inmate, num: 1 | 2, print: boolean, mode: string } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

type optionFields = 'sex'|'race'|'ethnicity'|'facility'

class InmateCareers extends React.PureComponent<InfoProps> {
    public componentDidMount() {
        this.props.fetchInmateCareers(this.props.num, this.props.inmate.info.recordID)
    }

    private displayNum = (min:number, max:number) => {
        if (min == max) {
          return `${min}`
        } else if (max == null) {
            return `> ${min}`
        } else if (min == null) {
            return `< ${max}`
        } else {
            return `${min} - ${max}`
        }
    }

    private displayDate = (startDate:string, endDate:string) => {
        if (startDate == endDate) {
          return `${startDate}`
        } else if (endDate == null) {
            return `> ${startDate}`
        } else if (startDate == null) {
            return `< ${endDate}`
        } else {
            return `${startDate} - ${endDate}`
        }
    }

    private displayTrait = (trait:any) => {
        switch (trait.kind) {
        case 'numeric':
          var nums = _.map(trait.nums, (nums:any) => this.displayNum(nums.min, nums.max))
          if (nums.length > 1) {
              return `${pluralize(trait.field).capitalize()}: ${nums.join(', ')}`
          } else {
              return `${trait.field.capitalize()}: ${nums.join(', ')}`
          }
        case 'date':
          var dates = _.map(trait.dates, (dates:any) => this.displayDate(dates.startDate, dates.endDate))
          if (dates.length > 1) {
              return `${pluralize(trait.field).capitalize()}: ${dates.join(', ')}`
          } else {
              return `${trait.field.capitalize()}: ${dates.join(', ')}`
          }
        case 'option':
            var values = _.map(trait.values, (key:string) => _.find(this.props.inmate.options[trait.field as optionFields], (opt:any) => opt.value == key).text)
            if (values.length > 1) {
                return `${pluralize(trait.field).capitalize()}: ${values.join(', ')}`
            } else {
                return `${trait.field.capitalize()}: ${values.join(', ')}`
            }
        }
    }

    public render() {
      const careerPanels = this.props.inmate.careers.data.map((career:any) => {
        return { key: career.recordID, title: { content:
          <Fragment>
            {career.name}
          </Fragment>
        }, content: { content:
          <Fragment>
            <div style={{whiteSpace:'normal'}}>{career.narrative}</div>
            <div style={{whiteSpace:'normal',marginTop:20}}>
              <Header as='h4'>Prerequisites</Header>
              <Progress percent={career.prePercent} size='small' indicating style={{marginBottom:15}} />
              <Table compact celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.filter(career.templates, (template:any) => template.pre).map((template:any, t:number) => <Table.Row positive={template.status == 'completed'} warning={template.status == 'scheduled'} error={['completed','scheduled','available'].includes(template.status) ? false : true}>
                    <Table.Cell>
                      {this.props.mode == 'dual' ? <Popup
                        hideOnScroll
                        on='click'
                        trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block'}}>{template.name}</div>}
                      >
                        <Popup.Header>Open in pane</Popup.Header>
                        <Popup.Content>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(1, template.recordID) }}>1</Label>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(2, template.recordID) }}>2</Label>
                        </Popup.Content>
                      </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block'}} onClick={() => { this.props.fetchTemplate(this.props.mode == 'left' ? 1 : 2, template.recordID) }}>{template.name}</div>}
                    </Table.Cell>
                    <Table.Cell>Class</Table.Cell>
                    <Table.Cell>{template.status == 'completed' ? 'Completed' : template.status == 'scheduled' ? 'Scheduled' : template.status == 'available' ? 'Available' : 'Unavailable'}</Table.Cell>
                  </Table.Row>)}
                  {_.filter(career.traits, (trait:any) => trait.pre).map((trait:any, t:number) => <Table.Row positive={trait.fulfilled}>
                    <Table.Cell>{this.displayTrait(trait)}</Table.Cell>
                    <Table.Cell>Trait</Table.Cell>
                    <Table.Cell>{trait.fulfilled ? 'Fulfilled' : 'Not Fulfilled'}</Table.Cell>
                  </Table.Row>)}
                </Table.Body>
              </Table>
            </div>
            <div style={{whiteSpace:'normal',marginTop:20}}>
              <Header as='h4'>Requisites</Header>
              <Progress percent={career.percent} size='small' indicating style={{marginBottom:15}} />
              <Table compact celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.filter(career.templates, (template:any) => !template.pre).map((template:any, t:number) => <Table.Row positive={template.status == 'completed'} warning={template.status == 'scheduled'} error={['completed','scheduled','available'].includes(template.status) ? false : true}>
                    <Table.Cell>
                      {this.props.mode == 'dual' ? <Popup
                        hideOnScroll
                        on='click'
                        trigger={<div className='link' style={{whiteSpace:'normal',display:'inline-block'}}>{template.name}</div>}
                      >
                        <Popup.Header>Open in pane</Popup.Header>
                        <Popup.Content>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(1, template.recordID) }}>1</Label>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchTemplate(2, template.recordID) }}>2</Label>
                        </Popup.Content>
                      </Popup> : <div className='link' style={{whiteSpace:'normal',display:'inline-block'}} onClick={() => { this.props.fetchTemplate(this.props.mode == 'left' ? 1 : 2, template.recordID) }}>{template.name}</div>}
                    </Table.Cell>
                    <Table.Cell>Class</Table.Cell>
                    <Table.Cell>{template.status == 'completed' ? 'Completed' : template.status == 'scheduled' ? 'Scheduled' : template.status == 'available' ? 'Available' : 'Unavailable'}</Table.Cell>
                  </Table.Row>)}
                  {_.filter(career.traits, (trait:any) => !trait.pre).map((trait:any, t:number) => <Table.Row positive={trait.fulfilled}>
                    <Table.Cell>{this.displayTrait(trait)}</Table.Cell>
                    <Table.Cell>Trait</Table.Cell>
                    <Table.Cell>{trait.fulfilled ? 'Fulfilled' : 'Not Fulfilled'}</Table.Cell>
                  </Table.Row>)}
                </Table.Body>
              </Table>
            </div>
          </Fragment>
        }}
      })

      return (
        <Container fluid className='full-accord'>
          <Header as='h3' style={{marginTop:10,marginBottom:0,display:'inline-block'}}>Career Pathways</Header>
          {this.props.inmate.careers.data.length > 0 ? <Fragment>
            <Header as='h4'>Assigned</Header>
            <Accordion styled panels={careerPanels}/>
          </Fragment> : null}
          {this.props.inmate.careers.recommendations.length > 0 ? <Fragment>
            <Header as='h4'>Recommended</Header>
            <Table compact celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Career</Table.HeaderCell>
                  <Table.HeaderCell>Narrative</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Code</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Progress</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.inmate.careers.recommendations.map((rec:any, r:number) => (
                  <Table.Row key={r}>
                    <Table.Cell>
                      {this.props.mode == 'dual' ? (
                        <Popup
                          hideOnScroll
                          on='click'
                          trigger={<div className='link'>{rec.name}</div>}
                        >
                          <Popup.Header>Open in pane</Popup.Header>
                          <Popup.Content>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchCareer(1, rec.recordID, 'pathway') }}>1</Label>
                            <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchCareer(2, rec.recordID, 'pathway') }}>2</Label>
                          </Popup.Content>
                        </Popup>
                      ) : (<div className='link' onClick={() => { this.props.fetchCareer(this.props.mode == 'left' ? 1 : 2, rec.recordID, 'pathway') }}>{rec.name}</div>)}
                    </Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'normal'}}>{rec.narrative}</div></Table.Cell>
                    <Table.Cell>{rec.code}</Table.Cell>
                    <Table.Cell><Progress indicating percent={rec.percent} style={{margin:0}} /></Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Fragment> : null}
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num, print: ownProps.print, mode: state.panes.mode } },
  PanesStore.actionCreators
)(InmateCareers as any)